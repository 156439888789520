<template>
  <v-container id="staff" class="py-0">
    <v-row>
      <v-col>
        <h2 class="align-self-end page-title">
          <v-btn
            icon
            class="mr-2"
            color="grey"
            :ripple="false"
            @click="$router.push('/settings/companies')"
            v-if="authEsa"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <span v-if="authEsa">{{ orgName }}</span>
          <span v-else>{{ $t(`settings.title.manageCompany`) }}</span>
        </h2>
      </v-col>
      <v-col cols="12" class="pt-0">
        <SettingsNavigation />
      </v-col>
    </v-row>
    <v-row class="flex-column flex-sm-row flex-md-row-reverse">
      <v-col
        cols="12"
        sm="12"
        md="4"
        class="d-flex justify-end"
        v-if="(!authEsa && isAvailableSettings) || (authEsa && isAdmin)"
      >
        <v-btn
          id="addEmployee"
          elevation="1"
          color="blue-grey lighten-5"
          min-width="265px"
          height="39px"
          @click="$emit('mode', [`employee/add${queryOrgId}`, true])"
        >
          {{ $t("action.addEmployee") }}
        </v-btn>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <SettingsFilteredSelect
          :options="options.allStores"
          :text="`storeName`"
          :value="`storeId`"
          :label="`chooseSalon`"
          @selected="filterBySelected"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <BaseSearch :label="`searchByTable`" @search="setSearch" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-if="showAlert.show">
        <v-alert
          :class="showAlert.class"
          :color="showAlert.color"
          :icon="showAlert.icon"
          prominent
          dense
        >
          <v-row align="center">
            <v-col cols="12" md="8">
              {{ showAlert.msg }}
            </v-col>
            <v-col
              cols="12"
              md="4"
              class="d-flex justify-end flex-wrap flex-md-nowrap"
            >
              <v-btn
                elevation="0"
                height="39px"
                text
                @click="showAlert.show = false"
              >
                {{ $t("action.close") }}
              </v-btn>
              <v-btn
                color="privat"
                elevation="0"
                height="39px"
                dark
                @click="filterUpdateStatus"
              >
                {{ $t("action.show") }} {{ `(${needUpdateStatus.length})` }}
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
      <v-col cols="12" v-if="showAlert.chip">
        <v-chip class="mb-1" small close @click:close="closeChip">
          {{ showAlert.msgChip }}
        </v-chip>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="staff"
          :search="search"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          @page-count="pageCount = $event"
          :loading="loading"
          sort-by="_id"
          class="elevation-1"
          locale="uk"
        >
          <v-progress-linear
            v-show="loading"
            color="privat"
            indeterminate
          ></v-progress-linear>
          <template v-slot:loading>
            <div class="d-flex flex-column justify-center align-center">
              <span class="text-subtitle-1 pt-7 pb-5">
                {{ $t("load") }}
              </span>
            </div>
          </template>
          <template v-slot:no-data>
            <div class="d-flex flex-column justify-center align-center mb-6">
              <span class="text-subtitle-1 pt-7 pb-5">
                {{ $t("settings.notEmployees") }}
              </span>
            </div>
          </template>
          <template v-slot:item.role="{ item }">
            <span>{{ item.role.label }}</span>
          </template>
          <template v-slot:item._status="{ item }">
            <span
              v-if="!item.isActualEndsSoon"
              class="d-flex align-center"
              :class="{
                'red--text': item.status === 'BLOCK_GO'
              }"
            >
              <v-icon
                :class="{
                  'red--text':
                    item.status === 'BLOCK_GO' || item.status === 'FIRED',
                  'privat--text': item.status === 'ACTUAL',
                  'orange--text': item.status === 'LOCK_BOSS',
                  'teal--text text--darken-3': item.status === 'NOT_ACTUAL'
                }"
                class="icon__state mr-1"
              >
                mdi-checkbox-blank-circle
              </v-icon>
              {{ item._status }}
            </span>
            <v-tooltip v-if="item.isActualEndsSoon" left>
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                  class="d-flex align-center"
                  :class="{
                    'red--text': item.status === 'BLOCK_GO'
                  }"
                >
                  <v-icon
                    :class="{
                      'red--text':
                        item.status === 'BLOCK_GO' || item.status === 'FIRED',
                      'privat--text': item.status === 'ACTUAL',
                      'orange--text': item.status === 'LOCK_BOSS',
                      'teal--text text--darken-3': item.status === 'NOT_ACTUAL'
                    }"
                    class="icon__state mr-1"
                  >
                    mdi-checkbox-blank-circle
                  </v-icon>
                  {{ item._status }}
                </span>
              </template>
              <span
                v-if="item.status === 'BLOCK_GO' || item.status === 'LOCK_BOSS'"
                v-html="$i18n.t('settings.tooltip.isActualEndsSoonAndBlocked')"
              >
              </span>
              <span
                v-if="item.status === 'ACTUAL'"
                v-html="$i18n.t('settings.tooltip.isActualEndsSoon')"
              ></span>
              <span
                v-if="item.status === 'NOT_ACTUAL'"
                v-html="$i18n.t('settings.tooltip.notActual')"
              ></span>
            </v-tooltip>
          </template>
          <template v-slot:item.actualDt="{ item }">
            <span
              v-if="item.status !== 'FIRED'"
              :class="{
                'red--text': item.isActualEndsSoon
              }"
            >
              {{ item.actualDt | date("numeric") }}
            </span>
            <span v-if="item.status === 'FIRED'">-</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <SettingsStaffActions
              :item="item"
              @actions="clickActions"
              v-if="!authEsa"
            />
            <SettingsStaffActionsEsa
              :item="item"
              @actions="clickActions"
              v-if="authEsa"
            />
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row v-if="lengthStaff && lengthStaff > 10">
      <v-col cols="12" sm="10" class="align-self-center">
        <v-pagination
          v-model="page"
          :length="pageCount"
          :total-visible="7"
          color="privat"
        ></v-pagination>
      </v-col>
      <v-col cols="4" sm="2" offset="8" offset-sm="0">
        <v-select
          :label="$i18n.t('label.onPage')"
          :value="itemsPerPage"
          :items="prePages"
          @change="itemsPerPage = parseInt($event, 10)"
        ></v-select>
      </v-col>
    </v-row>

    <base-snackbar :props="snack" :show="show" @close="show = false" />
  </v-container>
</template>

<script>
import SettingsNavigation from "@/components/settings/SettingsNavigation";
import BaseSearch from "@/components/BaseSearch";
import SettingsFilteredSelect from "@/components/settings/SettingsFilteredSelect";
import showSnack from "@/mixins/showSnack.mixins";
import { mapGetters } from "vuex";
import errorHandler from "@/utils/errorHandler";
import SettingsStaffActions from "@/components/settings/SettingsStaffActions";
import SettingsStaffActionsEsa from "@/components/settings/SettingsStaffActionsEsa";

export default {
  name: "Staff",
  mixins: [showSnack],
  props: {
    options: Object,
    company: Object,
    updateStaff: Boolean
  },
  data() {
    return {
      loading: true,
      search: "",
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      prePages: [10, 20, 50, 100, 200],
      staff: [],
      needUpdateStatus: [],
      showAlert: {
        show: false,
        icon: "mdi-account-clock",
        class: "mb-1 py-1",
        color: "blue-grey lighten-5",
        msg: "",
        msgChip: ""
      }
    };
  },
  components: {
    SettingsNavigation,
    BaseSearch,
    SettingsFilteredSelect,
    SettingsStaffActions,
    SettingsStaffActionsEsa
  },
  computed: {
    ...mapGetters(["getStaff", "getPointOfEntry", "getUser"]),
    sortByText() {
      return this.$i18n.t("label.sortByText");
    },
    headers() {
      return [
        {
          text: this.$i18n.t("settings.columns.role"),
          value: "_role",
          align: "start"
        },
        { text: this.$i18n.t("settings.columns.address"), value: "address" },
        { text: this.$i18n.t("settings.columns.fio"), value: "fio" },
        {
          text: this.$i18n.t("settings.columns.status"),
          value: "_status"
        },
        {
          text: this.$i18n.t("settings.columns.actualDt"),
          value: "actualDt"
        },
        {
          text: "",
          value: "actions",
          sortable: false,
          align: "end",
          width: "75px"
        }
      ];
    },
    lengthStaff() {
      return this.staff.length;
    },
    authEsa() {
      return this.getPointOfEntry;
    },
    isAvailableSettings() {
      return this.getUser?.isManageAvailable;
    },
    isAdmin() {
      return this.getUser?.userGrants?.includes("ADMINISTRATOR");
    },
    orgName() {
      let orgName = this.$i18n.t("settings.title.manageCompany");

      if (this.authEsa) {
        orgName = this.company?.name || this.selectedCompany?.name;
      }

      return orgName;
    },
    selectedCompany() {
      return this.options.companies.find(
        el => el.id === +this.$route.query.orgId
      );
    },
    queryOrgId() {
      let query = "";

      if (this.authEsa) {
        query = `?orgId=${this.$route.query.orgId}`;
      }

      return query;
    }
  },
  methods: {
    clickActions(data) {
      let [action, item] = data;

      switch (action) {
        case "DELETE":
          this.$emit("event", ["removeEmployee", item]);
          break;
        case "EDIT":
          this.$emit("event", ["editEmployee", item]);
          break;
        case "REACTIVATE":
          item.status === "NOT_ACTUAL"
            ? this.$emit("event", ["showDialogActiveEmployee", item])
            : this.$emit("event", ["showDialogReactiveEmployee", item]);
          break;
        case "LOCK":
          this.$emit("event", ["showDialogLockEmployee", item]);
          break;
        case "UNLOCK":
          this.$emit("event", ["showDialogUnlockEmployee", item]);
          break;
        case "BLOCK":
          this.$emit("event", ["showDialogBlockEmployee", item]);
          break;
        case "UNBLOCK":
          this.$emit("event", ["showDialogUnblockEmployee", item]);
          break;
      }
    },
    setSearch(data) {
      this.search = data;
    },
    filterBySelected(id) {
      if (id !== "ALL") {
        this.staff = this.getStaff;
        this.staff = this.staff.filter(el => el.storeId === id);
        this.showAlert.show = false;
      } else {
        this.staff = this.getStaff;
      }
    },
    filterUpdateStatus() {
      this.staff = this.staff.filter(el => el.isActualEndsSoon);
      this.showAlert.show = false;
      this.showAlert.chip = true;
    },
    closeChip() {
      this.showAlert.chip = false;
      this.staff = this.getStaff;
    },
    getEmployees() {
      this.loading = true;
      this.$store
        .dispatch("getEmployees", this)
        .then(() => {
          this.staff = this.getStaff;
          this.findNeedUpdateStatus();
        })
        .catch(err => {
          let errorCode = errorHandler(err, "getData");

          this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
          console.log("catch getEmployees");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    findNeedUpdateStatus() {
      this.needUpdateStatus = this.staff.filter(el => el.isActualEndsSoon);
      if (this.needUpdateStatus.length) {
        this.showAlert.show = true;
        this.showAlert.msg = this.$i18n.t("settings.alert.msgNeedUpdateStatus");
        this.showAlert.msgChip = this.$i18n.t("settings.alert.msgChip");
      }
    },
    getStaffCompany() {
      let orgId = this.company?.id || this.$route.query.orgId;

      this.loading = true;
      this.$store
        .dispatch("getStaffCompany", { orgId: orgId, self: this })
        .then(() => {
          this.staff = this.getStaff;
        })
        .catch(err => {
          let errorCode = errorHandler(err, "getData");

          this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
          console.log("catch getStaffCompany");
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  watch: {
    updateStaff() {
      if (this.authEsa) {
        this.getStaffCompany();
      } else {
        this.getEmployees();
      }
    }
  },
  mounted() {
    if (this.authEsa) {
      this.$emit("event", ["getEsaDefaultSettings", false]);
      this.getStaffCompany();
    } else {
      this.getEmployees();
    }
  },
  created() {
    this.$emit("mode", []);
  }
};
</script>

<style lang="scss">
#staff {
  .v-text-field__details {
    display: none;
  }
  .v-alert {
    .v-btn {
      &:first-child {
        margin-right: 20px;
        @media (max-width: 600px) {
          margin-right: 0;
          margin-bottom: 16px;
        }
      }
      @media (max-width: 600px) {
        width: 100%;
      }
    }
  }
  .v-alert__icon {
    margin-right: 20px;
    margin-left: 4px;
    min-width: 30px;
    height: 30px;
    font-size: 23px;
    &::before {
      margin-top: -2px;
      margin-left: 3px;
      color: #212121;
    }
    &::after {
      opacity: 0.5;
      color: #ff5252;
    }
    @media (max-width: 959.9px) {
      align-self: start;
      margin-top: 20px;
    }
  }
  .v-data-table {
    th:nth-child(2) {
      width: 400px;
      min-width: 250px;
    }
    th:nth-child(3) {
      width: 300px;
      min-width: 200px;
    }
    th:nth-child(4) {
      width: 140px;
      min-width: 140px;
    }
    td .v-icon.icon__state {
      font-size: 14px;
      margin-bottom: 0;
      @media (max-width: 600px) {
        margin-bottom: 2px;
      }
    }
  }
  .tail-text {
    color: #00695c;
  }
}
#addEmployee {
  @media (max-width: 600px) {
    width: 100%;
  }
}
</style>
