<template>
  <v-menu
    bottom
    left
    transition="slide-y-transition"
    v-if="item.status !== 'FIRED'"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn id="openAction" icon v-bind="attrs" v-on="on" :ripple="false">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on">
              mdi-dots-vertical
            </v-icon>
          </template>
          <span>{{ $t("action.openAction") }}</span>
        </v-tooltip>
      </v-btn>
    </template>

    <v-list>
      <v-list-item
        v-if="item.status === 'BLOCK_GO'"
        id="unlock"
        @click="$emit('actions', ['UNBLOCK', item])"
      >
        <v-list-item-title class="d-flex align-center">
          <v-icon medium class="mr-2" color="privat">
            mdi-account-check
          </v-icon>
          {{ $t("action.unlock") }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="item.status !== 'BLOCK_GO' && item.status !== 'FIRED'"
        id="lock"
        @click="$emit('actions', ['BLOCK', item])"
      >
        <v-list-item-title class="d-flex align-center">
          <v-icon medium class="mr-2" color="red darken-3">
            mdi-account-lock
          </v-icon>
          {{ $t("action.lock") }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item id="edit" @click="$emit('actions', ['EDIT', item])">
        <v-list-item-title class="d-flex align-center">
          <v-icon medium class="mr-2" color="blue darken-3">
            mdi-account-edit
          </v-icon>
          {{ $t("action.edit") }}
        </v-list-item-title>
      </v-list-item>
      <!-- ПОД ЗНАКОМ ВОПРОСА -->
      <!--<v-list-item
        v-if="item.status !== 'FIRED'"
        id="delete"
        @click="$emit('actions', ['DELETE', item])"
      >
        <v-list-item-title class="d-flex align-center">
          <v-icon medium class="mr-2" color="red darken-3">
            mdi-account-remove
          </v-icon>
          {{ $t("action.delete") }}
        </v-list-item-title>
      </v-list-item>-->
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "SettingsStaffActionsEsa",
  props: {
    item: Object
  }
};
</script>
