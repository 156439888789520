<template>
  <v-select
    id="filteredSalon"
    v-model="selected"
    :items="options"
    :item-text="text"
    :item-value="value"
    :label="$i18n.t(`label.${label}`)"
    color="privat"
    outlined
    dense
    @change="$emit('selected', selected)"
  ></v-select>
</template>

<script>
export default {
  name: "SettingsFilteredSelect",
  props: {
    options: {
      type: Array
    },
    label: {
      type: String,
      default: "choose"
    },
    text: {
      type: String,
      default: "label"
    },
    value: {
      type: String,
      default: "value"
    }
  },
  data() {
    return {
      selected: "ALL"
    };
  },
  mounted() {
    if (this.options.length) {
      this.selected = this.options[0].storeId;
    }
  }
};
</script>
