<template>
  <v-bottom-navigation min-height="48">
    <v-btn id="nav-staff" :to="`/settings/staff${query}`" :ripple="false">
      {{ $t("nav.staff") }}
    </v-btn>
    <v-btn id="nav-stores" :to="`/settings/stores${query}`" :ripple="false">
      {{ $t("nav.salon") }}
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
export default {
  name: "SettingsNavigation",
  data() {
    return {
      query: ""
    };
  },
  methods: {
    setQuery() {
      let query = this.$route.query;

      if (query?.orgId) {
        this.query = `?orgId=${query.orgId}`;
      } else {
        this.query = "";
      }
    }
  },
  mounted() {
    this.setQuery();
  }
};
</script>

<style scoped lang="scss">
.v-bottom-navigation {
  justify-content: start;
  border-bottom: 1px solid rgb(0 0 0 / 15%);
}
</style>
