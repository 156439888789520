<template>
  <v-text-field
    v-model="search"
    append-icon="mdi-magnify"
    :label="$t(`label.${label}`)"
    color="privat"
    single-line
    outlined
    clearable
    dense
    hide-details
    @input="$emit('search', search)"
    autocomplete="off"
  ></v-text-field>
</template>

<script>
export default {
  name: "BaseSearch",
  props: {
    label: {
      type: String,
      default: "search"
    }
  },
  data() {
    return {
      search: ""
    };
  }
};
</script>
